
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'taxi-meter-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<string>('');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('taxiMeterCreating'), [
          translate('assets'),
        ]);
      });

      const shpmentSchema = Yup.object().shape({
        fromGovernorateId: Yup.string()
          .required(() => translate('FROM_CITY_IS_REQUIRED_FIELD'))
          .label('From'),
        toGovernorateId: Yup.lazy((value) => {
          if (selectedItem.value === 'with-different-city') {
            return Yup.string()
              .required(() => translate('TO_CITY_IS_REQUIRED_FIELD'))
              .label('To');
          }
          return Yup.string().label('To');
        }),
        /*
        shipmentData: Yup.array().of(
          Yup.object().shape({
            rideFare: Yup.string().required('Ride Fare is required'),
            commission: Yup.string().required('Commission is required'),
          })
        ),*/
      });

      const sizeIds = await store.dispatch(Actions.GET_SHIPMENT_SIZES);

      const onSubmitCreate = async (values) => {
        console.log(values.shipmentData)
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const payload = {
          fromGovernorateId: values.fromGovernorateId,
          toGovernorateId: values.toGovernorateId || values.fromGovernorateId,
          sizePrice: values.shipmentData.map((shipmentData, index) => ({
            sizeId:
              shipmentData.rideFare && shipmentData.commission
                ? sizeIds[index].id
                : null,
            rideFare: Number(shipmentData.rideFare),
            commission: Number(shipmentData.commission),
          })),
        };
        await store.dispatch(Actions.CREATE_TAXI_METER, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_TAXI_METER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'taxi-meter-listing' });
          });
        }
      };

      await store.dispatch(Actions.GET_ALL_GOVERNORATES);

      return {
        onSubmitCreate,
        governorates: computed(() => store.getters.allGovernoratesList),
        shipmentSizes: computed(() => store.getters.shipmentSizeList),
        submitButton,
        selectedItem,
        translate,
        shipmentData: [],
        shpmentSchema,
        goBack,
        can,
      };
    },
  });
